/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
html,
body {
  font-family: 'poppins', sans-serif;
  margin: 0;
  font-weight: 400;
  height: auto;
  min-height: 100svh;
}

.header-icon {
  width: 29px;
  height: 18px;
}

.spacing_non_map_pages {
  height: 100vh;
  padding: 1.2em;
  background-color: #fafafa;
  border-left: 2px solid #e2dff0;
}

.header {
  margin-left: 0.75em;
}

.global_tile {
  border: 1px solid #0000001f;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.global_page_background {
  background-color: #fafafa;
}

.global_btn {
  border: 2px solid #0000001f;
  /* border: none; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  border-radius: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.global_btn:hover {
  border: 2px solid #00000051;
}

.global_devider {
  background-color: lightgray;
  height: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.global_devider_vertical {
  background-color: lightgray;
  width: 1px;
  margin-left: 1em;
  margin-right: 1em;
}

.global_title {
  padding: 8px;
  color: #6356A1;
  font-size: 28px;
  margin: 0;
}


.global_input_field {
  outline: none;
  width: 100%;
  min-width: 100px;
  border: none;
  border: solid 2px #6356A1;
  background-color: white;
  border-radius: 0.5em;
  height: 30px;
  padding: 0 0 0 10px;
  font-family: 'poppins', sans-serif;
  font-weight: 400;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.global_input_field input:focus {
  border-bottom: none;
  box-shadow: none;
}

.global-option-icon {
  padding: 0;
  color: #6356A1;
  font-size: 24px;
  display: block;
  height: 30px;
  width: 30px;
  margin-left: auto;
}

.global-option-icon svg {
  fill: #6356A1;
}

.global-option-icon:hover svg {
  fill: #6256a170;
}

.global_error_msg {
  color: red;
}

.global_success_msg {
  color: green;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #62cc74 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #62cc74 !important;
}